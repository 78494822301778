import ReactHighlighter from 'react-highlight-words'

const TextHighlighter = ({ text = '', filters = [] }) => {
  return (
    <ReactHighlighter
      highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      searchWords={filters.map(({ value }) => value)}
      autoEscape
      textToHighlight={text}
    />
  )
}

export default TextHighlighter
