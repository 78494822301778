import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import OrderAddressView from '$components/OrderAddressView'
import OrderedPartsTable from './OrderedPartsTable'
import OrderPartsListForm from './OrderPartsListForm'

const ExpandedRow = ({
  orderPartsList,
  orderPartFormData,
  resetOrderPartFormData,
  orderPartListStatus,
  editable,
  showProviderOffers,
  showRejectedProviderOffers,
  showOfferTerms,
  additionalOrderPartsTableColumns = [],
}) => {
  const { t } = useTranslation()

  return (
    <>
      <h4 style={{ padding: '15px 0' }}>{t('my_orders.ordered_parts')}</h4>
      <OrderedPartsTable
        orderPartsList={orderPartsList}
        orderPartFormData={orderPartFormData}
        resetOrderPartFormData={resetOrderPartFormData}
        orderPartListStatus={orderPartListStatus}
        editable={editable}
        showProviderOffers={showProviderOffers}
        showRejectedProviderOffers={showRejectedProviderOffers}
        showOfferTerms={showOfferTerms}
        additionalColumns={additionalOrderPartsTableColumns}
      />
      <div style={{ padding: '0 15px' }}>
        <Row>
          <Col span={16}>
            <OrderAddressView
              billingAddress={orderPartsList.billing_address}
              deliveryAddress={orderPartsList.delivery_address}
            />
          </Col>
          <Col span={8}>
            <OrderPartsListForm
              orderPartsList={orderPartsList}
              editable={false}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ExpandedRow
