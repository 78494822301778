import { fetchAllOrderPartsLists } from '$api/evoAPIs'
import Footer from '$components/Footer'
import { useOrderPartsListStatus } from '$components/OrderPartsListStatus'
import { StyledLayout } from '$globalStyledComponents/Layout'
import { useQuery } from '@tanstack/react-query'
import { Segmented } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandedRow from './ExpandedRow'
import Topwelcomecard from './Topwelcomecard'
import createColumns from './createColumns'
import { StyledCardContent, StyledTable } from './styledComponents'
import {
  OFFER_ACCEPTED,
  OFFER_MADE,
  REQUEST_RECEIVED,
  REQUEST_REJECTED,
} from '$components/OrderPartsListStatus'
import Form from '$components/Form'
import useMaterialMapping from '$utils/useMaterialMapping'

const { Content } = StyledLayout

const ReceivedRequests = () => {
  const { t } = useTranslation()

  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [formValues, setFormValues] = useState({})
  const [filter, setFilter] = useState(null)

  const { determineStatus } = useOrderPartsListStatus()

  const { data: orderPartLists, isLoading: orderPartsListsAreLoading } =
    useQuery(['allorderedpartslists'], fetchAllOrderPartsLists)

  const { data: materialsById, isLoading: materialsAreLoading } =
    useMaterialMapping()

  const handleFormChange = (formName, { forms }) => {
    if (formName) {
      setFormValues({
        ...formValues,
        [formName]: forms[formName].getFieldValue(),
      })
    }
  }

  const deleteProviderOffer = ({ orderPartsListId, orderPartId, offerId }) => {
    setFormValues((prevValues) => {
      const providerOffers =
        prevValues?.[orderPartsListId]?.[orderPartId]?.provider_offers

      if (providerOffers && offerId in providerOffers) {
        const { [offerId]: _offerToDelete, ...otherOffers } = providerOffers
        prevValues[orderPartsListId][orderPartId].provider_offers = otherOffers
      }

      return prevValues
    })
  }

  const deleteRule = ({
    orderPartsListId,
    orderPartId,
    offerId,
    typeOfRules,
    ruleId,
  }) => {
    setFormValues((prevValues) => {
      const ruleTypeKey = {
        price: 'price_rules',
        delivery: 'production_time_rules',
      }[typeOfRules]

      const rules =
        prevValues?.[orderPartsListId]?.[orderPartId]?.provider_offers?.[
          offerId
        ]?.[ruleTypeKey]

      if (rules && ruleId in rules) {
        const { [ruleId]: _ruleToDelete, ...otherRules } = rules

        prevValues[orderPartsListId][orderPartId].provider_offers[offerId][
          ruleTypeKey
        ] = otherRules
      }

      return prevValues
    })
  }

  const onTableRowExpand = (expanded, record) => {
    const keys = []
    if (expanded) {
      keys.push(record.id)
    }
    setExpandedRowKeys(keys)
  }

  const filterOptions = [
    {
      label: t('All'),
      value: null,
    },
    {
      label: t('service.order_part.status.request_received'),
      value: REQUEST_RECEIVED.toString(),
    },
    {
      label: t('service.order_part.status.request_rejected'),
      value: REQUEST_REJECTED.toString(),
    },
    {
      label: t('service.order_part.status.offer_made'),
      value: OFFER_MADE.toString(),
    },
    {
      label: t('service.order_part.status.offer_accepted'),
      value: OFFER_ACCEPTED.toString(),
    },
  ]

  const filteredData = orderPartLists?.filter((item) => {
    if (filter === REQUEST_RECEIVED.toString())
      return determineStatus(item) === REQUEST_RECEIVED
    else if (filter === REQUEST_REJECTED.toString())
      return determineStatus(item) === REQUEST_REJECTED
    else if (filter === OFFER_MADE.toString())
      return determineStatus(item) === OFFER_MADE
    else if (filter === OFFER_ACCEPTED.toString())
      return determineStatus(item) === OFFER_ACCEPTED
    else return true
  })

  const conditionalColumnsTitle = (title1, title2, title3) => {
    if (
      filter === REQUEST_RECEIVED.toString() ||
      filter === REQUEST_REJECTED.toString() ||
      filter === OFFER_MADE.toString()
    ) {
      return title1
    } else if (filter === OFFER_ACCEPTED.toString()) {
      return title2
    } else {
      return title3
    }
  }

  return (
    <StyledLayout className="layout">
      <Content>
        <h1 style={{ margin: '46px 0' }}>{t('received_requests.title')}</h1>
        <Topwelcomecard />
        <StyledCardContent>
          <Segmented
            options={filterOptions}
            value={filter}
            onChange={setFilter}
            style={{ marginBottom: '15px' }}
          />
          <Form.Provider onFormChange={handleFormChange}>
            {materialsAreLoading ? (
              t('Loading...')
            ) : (
              <StyledTable
                columns={createColumns(
                  t,
                  materialsById,
                  formValues,
                  determineStatus,
                  conditionalColumnsTitle,
                )}
                loading={orderPartsListsAreLoading && materialsAreLoading}
                dataSource={filteredData}
                rowKey={(data) => data.id}
                expandable={{
                  showExpandColumn: true,
                  expandRowByClick: true,
                  expandedRowRender: (record) => (
                    <ExpandedRow
                      orderPartsList={record}
                      setOrderPartsFormValues={(orderPartFormValues) =>
                        setFormValues({
                          ...formValues,
                          [record.id]: orderPartFormValues,
                        })
                      }
                      orderPartsFormValues={formValues[record.id]}
                      deleteRule={deleteRule}
                      deleteProviderOffer={deleteProviderOffer}
                    />
                  ),
                  expandedRowKeys: expandedRowKeys,
                  onExpand: onTableRowExpand,
                }}
              />
            )}
          </Form.Provider>
        </StyledCardContent>
      </Content>
      <Footer />
    </StyledLayout>
  )
}

export default ReceivedRequests
