import { evoConsolidation, evoKeycloak, realm } from '$api'
export async function fetchFileAsServiceUser({
  queryKey: [_parts, partId, _, file_name],
}) {
  const { data } = await evoConsolidation.get(
    `/parts/${partId}/file?` +
      new URLSearchParams({
        file_name,
      }),
    {
      responseType: 'blob',
    },
  )
  return new Blob([data], { type: 'application/octet-stream' })
}

export async function fetchFileAsServiceProvider({ queryKey: [_, file_name] }) {
  const { data } = await evoConsolidation.get(
    `/parts/files/?` +
      new URLSearchParams({
        file_name,
      }),
    {
      responseType: 'blob',
    },
  )
  return new Blob([data], { type: 'application/octet-stream' })
}

export async function getPartThumbnailAsServiceProvider({
  queryKey: [_, partId],
}) {
  const { data } = await evoConsolidation.get(`/parts/${partId}/thumbnail/`, {
    responseType: 'blob',
  })
  return data
}

export async function fetchAllBookedServices() {
  const res = await evoConsolidation.get('/booked-services/')
  return res?.data
}

export async function fetchAllBookedServicesQueries({
  queryKey: [
    _,
    { pageNr, pageSize, currentService, sorting = {}, clientPartId },
  ],
}) {
  const { key = 'time_stamp', order = 1 } = sorting
  const params = {
    page: pageNr,
    size: pageSize,
    service_name: currentService,
    key,
    order,
    client_part_id: clientPartId,
  }
  const paramsString = Object.entries(params)
    .filter(([_key, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
  const { data } = await evoConsolidation.get(
    `/booked-services/${paramsString ? `?${paramsString}` : ''}`,
  )
  return data
}

export async function fetchAllApplyBrandingParts() {
  const res = await evoConsolidation.get('/booked-services/apply-branding/')
  return res?.data
}

export async function fetchAllApplyTexturingParts() {
  const res = await evoConsolidation.get('/booked-services/apply-texturing/')
  return res?.data
}

export async function fetchAllGenerate3DModelParts() {
  const res = await evoConsolidation.get('/booked-services/generate-3d-model/')
  return res?.data
}

export async function fetchAllOrderPartsLists() {
  const { data } = await evoConsolidation.get(
    '/booked-services/order-parts-list/',
  )

  for (const orderPartsList of data) {
    for (const orderPart of orderPartsList.list_of_orderparts) {
      for (const offer of orderPart.provider_offers) {
        for (const priceRule of offer.price_rules) {
          priceRule.id = crypto.randomUUID()
        }
        for (const productionTimeRule of offer.production_time_rules) {
          productionTimeRule.id = crypto.randomUUID()
        }
      }
    }
  }

  return data
}

export async function setClientAcceptedOffer({
  order_parts_list_id,
  client_accepted_offer,
}) {
  const { data } = await evoConsolidation.patch(
    `/booked-services/order-parts-list/${order_parts_list_id}/client-accepted-offer?` +
      new URLSearchParams({
        client_accepted_offer,
      }),
  )
  return data
}

export async function setProviderAcceptedOffer({
  order_part_list_id,
  provider_accepted_offer,
  provider_rejection_reason,
}) {
  const { data } = await evoConsolidation.patch(
    `/booked-services/order-parts-list/${order_part_list_id}/provider-accepted-offer?` +
      new URLSearchParams({
        provider_accepted_offer,
        provider_rejection_reason,
      }),
  )
  return data
}

export const fetchFcServices = async ({ queryKey }) => {
  const [_, partId] = queryKey
  const { data } = await evoConsolidation.get(`/feasibility-checks/${partId}`)
  return data
}

export async function fetchAllFCServices() {
  const res = await evoConsolidation.get('/feasibility-checks/')
  return res?.data
}

export async function fetchAllOrderParts() {
  const res = await evoConsolidation.get('/booked-services/order-part/')
  return res?.data
}

export async function setOrderPartProviderOffers({
  order_part_id,
  provider_offers,
}) {
  const { data } = await evoConsolidation.patch(
    `/booked-services/order-part/${order_part_id}/provider_offers_as_service_provider`,
    provider_offers,
  )
  return data
}

export async function updateOrderPartProviderOffers({
  order_part_id,
  provider_offers,
}) {
  const { data } = await evoConsolidation.patch(
    `/booked-services/order-part/${order_part_id}/provider_offers_as_client`,
    provider_offers,
  )
  return data
}

export async function setOrderPartsListDateOfExpiration({
  order_parts_list_id,
  date_of_expiration,
}) {
  const { data } = await evoConsolidation.patch(
    `/booked-services/order-parts-list/${order_parts_list_id}/date_of_expiration`,
    date_of_expiration,
  )
  return data
}

export async function setOrderPartsListProviderNote({
  order_parts_list_id,
  provider_note,
}) {
  const { data } = await evoConsolidation.patch(
    `/booked-services/order-parts-list/${order_parts_list_id}/provider_note?` +
      new URLSearchParams({
        provider_note: provider_note || '',
      }),
  )
  return data
}

export async function setOrderPartRejectedByClient({
  order_part_id,
  rejected_by_client,
}) {
  const { data } = await evoConsolidation.patch(
    `/booked-services/order-part/${order_part_id}/rejected_by_client?` +
      new URLSearchParams({
        rejected_by_client,
      }),
  )
  return data
}

export async function fetchAllServices() {
  const res = await evoConsolidation.get('/services/')
  return res?.data
}

export async function fetchAllPartsConsolidation() {
  const res = await evoConsolidation.get('/parts/')
  return res?.data
}

export async function fetchLegalTexts({ queryKey: [_, language] }) {
  const res = await evoConsolidation.get('/legal_texts/', {
    headers: {
      'Accept-Language': language,
    },
  })
  return res?.data
}
export async function fetchUnreadNotifications() {
  const res = await evoConsolidation.get('/notifications/?read=false')
  return res?.data
}
export async function fetchReadNotifications() {
  const res = await evoConsolidation.get('/notifications/?read=true')
  return res?.data
}
export async function fetchAllNotifications() {
  const res = await evoConsolidation.get('/notifications/')
  return res?.data
}

export async function markNotificationAsRead(notification_id) {
  const { data } = await evoConsolidation.patch(
    `/notifications/${notification_id}/read`,
  )
  return data
}

export const notifications = `/notifications/`

export const postServices = `/services/`

export const postPartsConsolidation = `/parts/`

export const putBookedService = `/booked-services/`

export const postOrder = `/booked-services/order-part/`

export const postApplyBranding = `/booked-services/apply-branding/`

export const postApplyTexturing = `/booked-services/apply-texturing/`

export const postGenerate3DModel = `/booked-services/generate-3d-model/`

export const postSTLFileClient = `/parts/`

export const postFcService = `/feasibility-checks/` //deprecated

export const postFeasibilityCheck = `/booked-services/feasibility-check/`

export const postOrderList = `/booked-services/order-parts-list/`

export const consolidationPart = `/parts/`

//keycloak user endpoints

export const fetchLoggedInUser = async ({ queryKey }) => {
  const [_] = queryKey
  const { data } = await evoKeycloak.get(`/realms/${realm}/account`)
  return data
}

export const fetchLoggedInUserInfo = async ({ queryKey }) => {
  const [_] = queryKey
  const { data } = await evoKeycloak.get(
    `/realms/${realm}/protocol/openid-connect/userinfo`,
  )
  return data
}

export const fetchUser = async ({ queryKey }) => {
  const [_, userId] = queryKey
  const { data } = await evoKeycloak.get(
    `/admin/realms/${realm}/users/${userId}`,
  )
  return data
}

export const fetchUsers = async () => {
  const { data } = await evoKeycloak.get(`/admin/realms/${realm}/users`)
  return data
}

export const fetchUserRole = async ({ queryKey }) => {
  const [_, userId] = queryKey
  const { data } = await evoKeycloak.get(
    `/admin/realms/${realm}/users/${userId}/role-mappings/realm`,
  )
  return data
}

export const fetchCompanyOfUser = async ({ queryKey }) => {
  const [_, userId] = queryKey
  const { data } = await evoKeycloak.get(
    `/admin/realms/${realm}/users/${userId}/groups`,
  )
  return data
}

export const fetchCompanyData = async ({ queryKey }) => {
  const [_, groupId] = queryKey
  const { data } = await evoKeycloak.get(
    `/admin/realms/${realm}/groups/${groupId}`,
  )
  return data
}

export const fetchUsersOfCompany = async ({ queryKey }) => {
  const [_, companyId] = queryKey
  const { data } = await evoKeycloak.get(
    `/admin/realms/${realm}/groups/${companyId}/members`,
  )
  return data
}

export async function fetchAllCompanies() {
  const res = await evoKeycloak.get(`/admin/realms/${realm}/groups`)
  return res?.data
}

export async function fetchAllRoles() {
  const res = await evoKeycloak.get(`/admin/realms/${realm}/roles`)
  return res?.data
}

// create user
export const postUser = async ({
  firstName,
  lastName,
  email,
  username,
  company,
  password,
}) => {
  const response = await evoKeycloak.post(
    `/admin/realms/${realm}/users`,
    {
      firstName: firstName,
      lastName: lastName,
      email: email,
      username: username,
      groups: [company],
      credentials: [
        {
          type: 'password',
          value: password,
          temporary: false,
        },
      ],
      enabled: true,
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )

  const location_url = response?.headers?.location

  const [_loc, userId] = location_url.split(`/admin/realms/${realm}/users/`)

  return {
    id: userId,
  }
}

// update user
export const putUser = async ({ userId, firstName, lastName, email }) => {
  const response = await evoKeycloak.put(
    `/admin/realms/${realm}/users/${userId}`,
    {
      firstName: firstName,
      lastName: lastName,
      email: email,
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )

  return response
}

export const deleteUser = async ({ userId }) => {
  const response = await evoKeycloak.delete(
    `/admin/realms/${realm}/users/${userId}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )

  return response
}

export const postRoleMapping = async ({ userId, roleId, roleName }) => {
  const response = await evoKeycloak.post(
    `/admin/realms/${realm}/users/${userId}/role-mappings/realm`,
    [
      {
        id: roleId,
        name: roleName,
      },
    ],
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )

  return response
}

export const deleteRoleMapping = async ({ userId, roleId, roleName }) => {
  const response = await evoKeycloak.delete(
    `/admin/realms/${realm}/users/${userId}/role-mappings/realm`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: [
        {
          id: roleId,
          name: roleName,
        },
      ],
    },
  )

  return response
}

export async function fetchAllPrinters() {
  const res = await evoConsolidation.get('/printers/')
  return res?.data
}

export async function fetchAllMaterials() {
  const res = await evoConsolidation.get('/materials/')
  return res?.data
}

export const fetchMaterialById = async (materialId) => {
  const { data } = await evoConsolidation.get(`/materials/${materialId}/`)
  return data
}

export const fetchLabels = async ({
  queryKey: [_, { exclude_system_managed }],
}) => {
  const { data } = await evoConsolidation.get(
    `/labels/?` +
      new URLSearchParams({
        exclude_system_managed,
      }),
  )
  return data
}

export const fetchLabelsForBookedService = async ({
  queryKey: [_, bookedServiceId],
}) => {
  const { data } = await evoConsolidation.get(
    `/booked-services/${bookedServiceId}/labels`,
  )
  return data
}

export const removeLabelFromBookedService = async ({
  bookedServiceId,
  labelName,
}) => {
  const { data } = await evoConsolidation.delete(
    `/booked-services/${bookedServiceId}/labels/${labelName}`,
  )
  return data
}

export const addLabelToBookedService = async ({
  bookedServiceId,
  labelName,
}) => {
  const { data } = await evoConsolidation.post(
    `/booked-services/${bookedServiceId}/labels`,
    {
      label: labelName,
    },
  )
  return data
}
