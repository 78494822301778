import ServiceStatusButtons from '../../../PartsTable/ServiceStatusButtons'
import { PartNoBg } from '../../Defaultwizard/styledComponents'
import { UilCheck } from '@iconscout/react-unicons'
import { Button } from 'antd'
import TruncateText from '$components/TruncateText/TruncateText'

const createColumnsNotFeasible = (t) => {
  const columns = [
    {
      title: t('Part No'),
      dataIndex: 'id_part_client',
      render: (title) => <PartNoBg>{title}</PartNoBg>,
      width: '160px',
    },
    {
      title: t('Part Name'),
      dataIndex: 'name',
      render: (text) => <TruncateText text={text} />,
      ellipsis: true,
    },
    {
      title: t('service.feasibility_check.part_has_already_fc'),
      render: (record) => {
        const value = record?.attributes?.find(
          (x) => x.key === 'Feasibility Check',
        )?.value
        if (value === 'true' || value === 'false') {
          return (
            <Button
              style={{ margin: '-6px 0' }}
              type="secondary"
              shape="circle"
              icon={
                <UilCheck
                  style={{
                    height: '19px',
                    color: '#7CC334',
                    margin: '-3px 0px',
                  }}
                />
              }
            />
          )
        } else {
          return <>-</>
        }
      },
    },
    {
      title: t('Services'),
      width: '265px',
      render: (record) => (
        <ServiceStatusButtons variant={record.status_attributes} />
      ),
    },
  ]

  return columns
}

export default createColumnsNotFeasible
