import { useEffect, useState } from 'react'
import {
  UserOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  ShopOutlined,
  ThunderboltOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons'
import { Avatar, Select, Modal, Button, Divider, Segmented, theme } from 'antd'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import {
  StyledHorizontalMenue,
  StyledDropdownMenue,
  StyledSelect,
} from '$globalStyledComponents/HorizontalMenue'
import { StyledLink } from '$globalStyledComponents/Links'
import NotificationBell from './NotificationBell'
import '../assets/global-styles.less'
import Customlogo from '../assets/logos/Daimler_Buses_Logotype_1L_Black_RGB.svg'
import { useTranslation } from 'react-i18next'
import { ImprintText } from '../pages/shared/Imprint'
import { LoginOutlined } from '@ant-design/icons'
import { useUser, useLogout } from '$context/user'

const NAV_ITEMS_SERVICEUSER = [
  { label: 'Marketplace', key: 'marketplace' },
  { label: 'parts_master_data.title', key: 'partsmasterdata' },
  // { label: 'Supply', key: 'supply' },
  // { label: 'Monitor', key: 'monitor' },
  // { label: 'My Materials', key: 'mymaterials' }, // TODO: DWH-1688
  { label: 'My Services', key: 'myservices' },
  { label: 'My Orders', key: 'myorders' },
]
const NAV_ITEMS_SERVICEPROVIDER = [
  { label: 'ordered_services.title', key: 'orderedservices' },
  { label: 'my_printers.title', key: 'myprinters' },
  {
    label: 'received_requests.title',
    key: 'receivedrequests',
  },
]

const VIEW_SERVICEUSER = 'serviceuser'
const VIEW_SERVICEPROVIDER = 'serviceprovider'
const VIEW_EXPERT = 'expert'

const { useToken } = theme

function getDefaultView(user) {
  if (user.isServiceProvider && !user.isServiceUser) {
    return VIEW_SERVICEPROVIDER
  }
  if (user.isServiceUser && !user.isServiceProvider) {
    return VIEW_SERVICEUSER
  }
  return VIEW_EXPERT
}

const Topnav = (props) => {
  const user = useUser()
  const logout = useLogout()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const currentPath = useLocation().pathname
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { token } = useToken()
  const [view, setView] = useState(getDefaultView(user))

  useEffect(() => {
    if (currentPath.substring(1)) {
      props.setCurrent(currentPath.substring(1))
    }
  }, [currentPath, props])

  function translateAndAddClass(className) {
    return ({ label, ...item }) => ({
      ...item,
      label: t(label),
      className,
    })
  }

  const getNavItems = () => {
    if (!user) {
      return []
    }

    let items = []

    const showServiceuserItems =
      view === VIEW_SERVICEUSER || view === VIEW_EXPERT
    if (user.isServiceUser && showServiceuserItems) {
      items = [
        ...items,
        ...NAV_ITEMS_SERVICEUSER.map(translateAndAddClass('serviceuser')),
      ]
    }

    const showServiceproviderItems =
      view === VIEW_SERVICEPROVIDER || view === VIEW_EXPERT
    if (user.isServiceProvider && showServiceproviderItems) {
      items = [
        ...items,
        ...NAV_ITEMS_SERVICEPROVIDER.map(
          translateAndAddClass('serviceprovider'),
        ),
      ]
    }
    return items
  }

  const handleClick = (e) => {
    props.setCurrent(e.key)
    navigate('/' + e.key)
  }

  const { Option } = Select

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const showViewSwitch = user.isServiceUser && user.isServiceProvider
  const settingsitems = [
    // {
    //   key: '1',
    //   label: (
    //     <Link to={'/user'} replace onClick={() => props.setCurrent('user')}>
    //       {t('user_master_data.title')}
    //     </Link>
    //   ),
    // },
    // {
    //   key: '2',
    //   label: (
    //     <Link to={'/company'} onClick={() => props.setCurrent('company')}>
    //       {t('company_master_data.title')}
    //     </Link>
    //   ),
    // },
    ...(showViewSwitch
      ? [
          {
            label: (
              <Segmented
                options={[
                  {
                    label: t('view.serviceuser'),
                    value: VIEW_SERVICEUSER,
                    icon: <ShoppingCartOutlined />,
                  },
                  {
                    label: t('view.serviceprovider'),
                    value: VIEW_SERVICEPROVIDER,
                    icon: <ShopOutlined />,
                  },
                  {
                    label: t('view.expert'),
                    value: VIEW_EXPERT,
                    icon: <ThunderboltOutlined />,
                  },
                ]}
                value={view}
                onChange={setView}
              />
            ),
          },
        ]
      : []),
    {
      label: <div onClick={logout}>{t('Logout')}</div>,
    },
  ]

  return (
    <div className="navbar-header">
      <img
        width={200}
        src={token.logo || Customlogo}
        alt="Logo of the Digital Warehouse"
        style={{ margin: '2px 10px 0 0' }}
      />
      <StyledHorizontalMenue
        onClick={handleClick}
        selectedKeys={[props.current]}
        mode="horizontal"
        items={getNavItems()}
      />
      {user ? <NotificationBell /> : null}
      <div className="lang-switcher">
        <StyledSelect
          defaultValue={i18n.resolvedLanguage || 'en'}
          onChange={(lang) => i18n.changeLanguage(lang)}
          variant="borderless"
        >
          <Option value="de">DE</Option>
          <Option value="en">EN</Option>
          {process.env.NODE_ENV !== 'production' && (
            <Option value="cimode">FX</Option>
          )}
        </StyledSelect>
      </div>
      {user ? (
        <>
          <div className="navbar-user">
            {/* <Link
              to={'/user'}
              onClick={() => props.setCurrent('user')}
              style={{ marginTop: '2px' }}
            > */}
            <Avatar icon={<UserOutlined />} style={{ margin: '6px' }} />
            {/* </Link> */}
            <span
              style={{ fontSize: '12px' }}
              className="ant-menu-title-content"
            >
              {user.name}
            </span>
            <StyledDropdownMenue
              overlayStyle={{
                width:
                  user.isServiceProvider && user.isServiceUser
                    ? '340px'
                    : '160px',
                textAlign: 'right',
              }}
              menu={{ items: settingsitems }}
              placement="bottomRight"
              trigger={['click']}
            >
              <SettingOutlined
                style={{
                  fontSize: '18px',
                  margin: '0 0 0 8px',
                  cursor: 'pointer',
                  padding: '22px 0px 22px',
                }}
              />
            </StyledDropdownMenue>
          </div>
          <div className="info-circle">
            <InfoCircleOutlined
              style={{ fontSize: '16px', margin: '4px 8px 0 0' }}
              onClick={showModal}
            />
          </div>
        </>
      ) : (
        <Link to={'/login'}>
          <Button type="primary" icon={<LoginOutlined />} size="large">
            {t('login')}
          </Button>
        </Link>
      )}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button type="primary" onClick={handleOk} key="okButton">
            OK
          </Button>,
        ]}
      >
        <ImprintText />
        <div>
          <StyledLink to="/imprint" onClick={handleOk}>
            {t('Provider')}
          </StyledLink>
          <Divider type="vertical" />
          <StyledLink to="/data-privacy" onClick={handleOk}>
            {t('Data protection')}
          </StyledLink>
          <Divider type="vertical" />
          <StyledLink to="/legal-information" onClick={handleOk}>
            {t('Legal notices')}
          </StyledLink>
        </div>
      </Modal>
    </div>
  )
}

export default Topnav
