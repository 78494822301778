import { updateStatusAttributesAfterOrder } from '$api/client'
import {
  fetchAllOrderPartsLists,
  setClientAcceptedOffer,
  updateOrderPartProviderOffers,
} from '$api/evoAPIs'
import { CenteredSpinner } from '$components/Spinner'
import { isDemoError } from '$utils/demo'
import { currencyRenderer, dateWithTimeRenderer } from '$utils/i18n'
import useMaterialMapping from '$utils/useMaterialMapping'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button, Tag, message } from 'antd'
import Tooltip from '$components/Tooltip'
import { RedoOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandedRow from './ExpandedRow'
import { StyledTable, StyledLink } from './ExpandedRow/styledComponents'
import ExpirationDate from './ExpirationDate'
import { calculateOrderPartPrice, summarizeDeliveryTimes } from './utils'
import { useNavigate } from 'react-router-dom'
import Form from '$components/Form'
import TruncateText from '$components/TruncateText/TruncateText'

const OrderPartsListTable = () => {
  const { t } = useTranslation()
  const [messageApi, contextHolder] = message.useMessage()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  // store copies of the OrderPart Forms data, to calculate summary data in table
  const [orderPartFormData, setOrderPartFormData] = useState({})
  const [orderPartListStatus, setOrderPartListStatus] = useState({})

  const { data: orderPartLists, isLoading: orderPartsListsAreLoading } =
    useQuery(['allorderedpartslists'], fetchAllOrderPartsLists, {
      onSuccess: (res) => {
        setOrderPartFormData(orderPartListToFormData(res))
      },
    })

  const { data: materialsById, isLoading: materialsAreLoading } =
    useMaterialMapping()

  const updateProviderOffer = useMutation({
    mutationFn: updateOrderPartProviderOffers,
    onSuccess: (_resp, { order_part_list_id, order_part_id }) => {
      setOrderPartListStatus({
        ...orderPartListStatus,
        [order_part_list_id]: [
          ...(orderPartListStatus[order_part_list_id] ?? []),
          order_part_id,
        ],
      })
    },
  })

  const clientAcceptedOffer = useMutation({
    mutationFn: setClientAcceptedOffer,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['allorderedpartslists'] })

      for (let part of data.list_of_orderparts) {
        updateClientPartStatus.mutate(part)
      }
    },
    onError: (error) => {
      if (isDemoError(error)) {
        queryClient.invalidateQueries({ queryKey: ['allorderedpartslists'] })
      }
    },
  })

  const updateClientPartStatus = useMutation(updateStatusAttributesAfterOrder, {
    onError: (err) => {
      message.error(`${err.response.status} Could not update status`)
    },
  })

  const orderPartListToFormData = (orderPartList) => {
    // transform object to formData object
    // turn all arrays into objects with ids as key so we can map form items to object

    return Object.fromEntries(
      orderPartList.map((orderPartList) => [
        orderPartList.id,
        Object.fromEntries(
          orderPartList.list_of_orderparts.map((orderPart) => [
            orderPart.id,
            {
              ...orderPart,
              provider_offers: Object.fromEntries(
                orderPart.provider_offers.map((offer) => [
                  offer.offer_id,
                  offer,
                ]),
              ),
            },
          ]),
        ),
      ]),
    )
  }

  const resetOrderPartFormData = (orderPartListId, orderPartId) => {
    setOrderPartFormData({
      ...orderPartFormData,
      [orderPartListId]: {
        ...orderPartFormData[orderPartListId],
        [orderPartId]: orderPartLists
          .find((orderPartList) => orderPartList.id === orderPartListId)
          .list_of_orderparts.find((orderPart) => orderPart.id === orderPartId),
      },
    })
    queryClient.invalidateQueries({ queryKey: ['allorderedpartslists'] })
  }

  const handleFormChange = (formName, { forms }) => {
    const [orderPartListId, orderPartId] = formName
    setOrderPartFormData({
      ...orderPartFormData,
      [orderPartListId]: {
        ...orderPartFormData[orderPartListId],
        [orderPartId]:
          forms[`${orderPartListId},${orderPartId}`].getFieldValue(),
      },
    })
  }

  const handleFormFinish = async (formName, { values }) => {
    const [order_part_list_id, order_part_id] = formName

    updateProviderOffer.mutate(
      {
        order_part_list_id,
        order_part_id,
        provider_offers: Object.entries(values.provider_offers).map(
          ([offer_id, offer]) => ({ ...offer, offer_id }),
        ),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['allorderedpartslists'] })
        },
        onError: (error) => {
          messageApi.error('Saving data failed!')
          console.error(error)
        },
      },
    )
  }

  const handleAcceptOffer = (order_parts_list_id) => {
    clientAcceptedOffer.mutate(
      {
        order_parts_list_id,
        client_accepted_offer: true,
      },
      {
        onSuccess: () => {
          message.success('Offer accepted successfully.')
        },
        onError: (error) => {
          if (!isDemoError(error)) {
            message.error('Accepting offer failed!')
          }
        },
      },
    )
  }

  const openOrderService = (record) => {
    navigate('/marketplace/orderservice', {
      state: { orderToRepeat: JSON.stringify(record) },
    })
  }

  const columns = [
    {
      title: t('my_orders.order_nr'),
      dataIndex: 'order_number',
      render: (title) => (
        <div style={{ minWidth: '100px' }}>
          <b>{title}</b>
        </div>
      ),
    },
    {
      title: t('my_orders.order_date'),
      dataIndex: ['list_of_orderparts', 0, 'time_stamp'],
      render: (record) => dateWithTimeRenderer(t)(record),
    },
    {
      title: t('my_orders.date_of_expiration'),
      render: (record) => {
        if (!record.provider_accepted_offer) {
          return ''
        }

        return <ExpirationDate date_of_expiration={record.date_of_expiration} />
      },
    },
    {
      title: t('my_orders.parts_amount'),
      dataIndex: 'list_of_orderparts',
      render: (record) =>
        record.filter((orderPart) => !orderPart.rejected_by_client).length,
    },
    {
      title: t('my_orders.materials'),
      dataIndex: 'list_of_orderparts',
      render: (record) => {
        const materialNames = record
          .filter((orderPart) => !orderPart.rejected_by_client)
          .map((obj) => materialsById[obj.material]?.name)
          .join(', ')

        if (materialNames.length > 20) {
          return <TruncateText text={materialNames.substring(0, 30) + '...'} />
        } else {
          return <TruncateText text={materialNames} />
        }
      },
      ellipsis: true,
    },
    {
      title: t('my_orders.delivery_time'),
      render: (record) => {
        if (!record.provider_accepted_offer) {
          return ''
        }

        let offers_delivery_days
        if (orderPartFormData[record.id]) {
          offers_delivery_days = Object.values(orderPartFormData[record.id])
            .filter((orderPart) => !orderPart.rejected_by_client)
            .map((orderPart) =>
              Object.values(orderPart.provider_offers).map(
                (offer) => offer.delivery_days,
              ),
            )
            .flat()
        } else {
          offers_delivery_days = record.list_of_orderparts
            .filter((orderPart) => !orderPart.rejected_by_client)
            .map((orderPart) =>
              orderPart.provider_offers.map((offer) => offer.delivery_days),
            )
            .flat()
        }
        return t(
          'received_requests.days',
          summarizeDeliveryTimes(offers_delivery_days),
        )
      },
    },
    {
      title: t('my_orders.price_total'),
      render: (record) => {
        if (!record.provider_accepted_offer) {
          return ''
        }

        let batch_prices
        if (orderPartFormData[record.id]) {
          batch_prices = Object.values(orderPartFormData[record.id])
            .filter((orderPart) => !orderPart.rejected_by_client)
            .map((orderPart) =>
              calculateOrderPartPrice(Object.values(orderPart.provider_offers)),
            )
            .flat()
        } else {
          batch_prices = record.list_of_orderparts
            .filter((orderPart) => !orderPart.rejected_by_client)
            .map((orderPart) =>
              calculateOrderPartPrice(orderPart.provider_offers),
            )
            .flat()
        }
        return currencyRenderer(t)(batch_prices.reduce((sum, a) => sum + a, 0))
      },
    },
    {
      title: 'Status',
      render: (record) => {
        if (record.client_accepted_offer) {
          return (
            <Tooltip title={t('service.order_part.tooltip.offer_accepted')}>
              <Tag color="success">
                <TruncateText
                  text={t('service.order_part.status.offer_accepted')}
                />
              </Tag>
            </Tooltip>
          )
        } else if (record.provider_accepted_offer) {
          const orderPartList = orderPartLists.find(
            (orderPartList) => orderPartList.id === record.id,
          )
          const allReviewed = orderPartList.list_of_orderparts
            .filter((orderPart) => !orderPart.rejected_by_client)
            .every((orderPart) =>
              orderPartListStatus[record.id]?.includes(orderPart.id),
            )

          const isExpired = new Date(record.date_of_expiration) <= new Date()

          return (
            <Tooltip
              title={
                isExpired
                  ? t('service.order_part.tooltop.offer_expired')
                  : !allReviewed
                    ? t('service.order_part.tooltip.please_review')
                    : t('service.order_part.tooltip.accept_and_order')
              }
            >
              <Button
                type="primary"
                size="small"
                disabled={!allReviewed || isExpired}
                onClick={() => handleAcceptOffer(record.id)}
              >
                <TruncateText text={t('my_orders.accept_and_order')} />
              </Button>
            </Tooltip>
          )
        } else {
          return (
            <Tooltip title={t('service.order_part.tooltip.offer_pending')}>
              <Tag color="warning">
                <TruncateText
                  text={t('service.order_part.status.offer_pending')}
                />
              </Tag>
            </Tooltip>
          )
        }
      },
      ellipsis: true,
    },
    {
      title: '',
      render: (record) => {
        return (
          <StyledLink>
            <Tooltip title={t('my_orders.repeat_order')}>
              <RedoOutlined
                onClick={() => openOrderService(record)}
                style={{ fontSize: '16px' }}
              />
            </Tooltip>
          </StyledLink>
        )
      },
    },
  ]

  if (orderPartsListsAreLoading || materialsAreLoading) {
    return <CenteredSpinner />
  }

  return (
    <>
      {contextHolder}
      <Form.Provider
        onFormChange={handleFormChange}
        onFormFinish={handleFormFinish}
      >
        <StyledTable
          columns={columns}
          dataSource={orderPartLists}
          rowKey={(record) => record.id}
          expandable={{
            showExpandColumn: true,
            expandRowByClick: true,
            expandedRowRender: (record) => (
              <ExpandedRow
                orderPartsList={record}
                orderPartListStatus={orderPartListStatus}
                orderPartFormData={orderPartFormData[record.id]}
                resetOrderPartFormData={(orderPartId) =>
                  resetOrderPartFormData(record.id, orderPartId)
                }
                editable={!record.client_accepted_offer}
                showProviderOffers={record.provider_accepted_offer}
                showRejectedProviderOffers={false}
                showOfferTerms={record.provider_accepted_offer}
              />
            ),
          }}
        />
      </Form.Provider>
    </>
  )
}

export default OrderPartsListTable
