import { PartNoBg } from '../styledComponents'
import TruncateText from '$components/TruncateText/TruncateText'

const createColumnsSummary = (t, materialsById) => {
  const columns = [
    {
      title: t('Part No'),
      dataIndex: ['part', 'id_part_client'],
      render: (title) => <PartNoBg>{title}</PartNoBg>,
      width: '160px',
    },
    {
      title: t('Part Name'),
      dataIndex: ['part', 'name'],
      render: (text) => <TruncateText text={text} />,
      ellipsis: true,
    },
    {
      title: t('Material'),
      render: (record) => (
        <TruncateText text={materialsById[record.material_id]?.name} />
      ),
      ellipsis: true,
    },
    {
      title: t('Quantity'),
      dataIndex: 'amount',
    },
  ]

  return columns
}

export default createColumnsSummary
