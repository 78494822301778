import DatePicker from '$components/DatePicker'
import ExpirationDate from '$components/OrderPartsListTable/ExpirationDate'
import { Button, Input, Row, message, theme } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import {
  setOrderPartsListDateOfExpiration,
  setOrderPartsListProviderNote,
} from '$api/evoAPIs'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import Form from '$components/Form'

const { useToken } = theme
const { TextArea } = Input

const OrderPartsListForm = ({ form, orderPartsList, editable }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [messageApi, contextHolder] = message.useMessage()
  const { token } = useToken()

  const setDateOfExpiration = useMutation({
    mutationFn: setOrderPartsListDateOfExpiration,
  })

  const setProviderNoteMutation = useMutation({
    mutationFn: setOrderPartsListProviderNote,
  })

  const onOrderPartFormFinish = async ({
    provider_note,
    date_of_expiration,
  }) => {
    try {
      await setProviderNoteMutation.mutateAsync({
        order_parts_list_id: orderPartsList.id,
        provider_note: provider_note,
      })
      await setDateOfExpiration.mutateAsync({
        order_parts_list_id: orderPartsList.id,
        date_of_expiration: date_of_expiration,
      })
      messageApi.success('Saved successfully!')
      queryClient.invalidateQueries(['allorderedpartslists'])
    } catch (error) {
      console.error(error)
      messageApi.error('Could not save data!')
    }
  }

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        onFinish={onOrderPartFormFinish}
        layout="vertical"
        requiredMark={(label, info) => (
          <h4>
            {label}
            {info.required && (
              <span style={{ color: token.colorError, marginLeft: '5px' }}>
                *
              </span>
            )}
          </h4>
        )}
      >
        <Form.Item
          label={t('received_requests.date_of_expiration')}
          name={'date_of_expiration'}
          initialValue={
            orderPartsList?.date_of_expiration
              ? dayjs(orderPartsList.date_of_expiration)
              : undefined
          }
          rules={[
            {
              required: true,
              message: t('received_requests.date_of_expiration_required'),
            },
          ]}
        >
          {editable ? (
            <DatePicker
              style={{ width: '100%' }}
              disabledDate={(d) => !d || d.isBefore(new Date())}
              allowClear={false}
            />
          ) : (
            <ExpirationDate
              date_of_expiration={orderPartsList.date_of_expiration}
            />
          )}
        </Form.Item>
        <Form.Item
          label={t('received_requests.provider_note')}
          name={'provider_note'}
          initialValue={orderPartsList?.provider_note}
        >
          {editable ? (
            <TextArea rows={4} placeholder="" />
          ) : (
            orderPartsList?.provider_note
          )}
        </Form.Item>
        {editable && (
          <Form.Item>
            <Row justify={'end'}>
              <Button type="primary" htmlType="submit">
                {t('button.save')}
              </Button>
            </Row>
          </Form.Item>
        )}
      </Form>
    </>
  )
}

export default OrderPartsListForm
