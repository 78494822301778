import { useState } from 'react'
import { Route, Routes as RouterDomRoutes, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import HeaderRoute from './components/HeaderRoute'
import ProtectedRoute from '$components/ProtectedRoute'
import Marketplace from './pages/serviceuser/Marketplace'
import Bookservice from './pages/serviceuser/Marketplace/Bookservice'
import Login from './pages/shared/Login'
import Monitor from './pages/shared/Monitor'
import Mymaterials from './pages/serviceuser/Mymaterials'
import Myservices from './pages/serviceuser/Myservices'
import Myorders from '$pages/serviceuser/Myorders'
import Partsmasterdata from './pages/serviceuser/Partsmasterdata'
import Orderedservices from './pages/serviceprovider/Orderedservices'
import Myprinters from './pages/serviceprovider/Myprinters'
import ReceivedRequests from './pages/serviceprovider/ReceivedRequests'
import Supply from './pages/shared/Supply'
import User from './pages/shared/User'
import Company from './pages/shared/Company'
import Imprint from './pages/shared/Imprint'
import DataPrivacy from './pages/shared/DataPrivacy'
import LegalInformation from './pages/shared/LegalInformation'
import GeneralTerms from './pages/shared/GeneralTerms'
import NotFoundPage from './pages/shared/404'
import { useUser, useToken } from '$context/user'
import { CenteredSpinner } from '$components/Spinner'

const Routes = () => {
  const [current, setCurrent] = useState('marketplace')
  const { loginInProgress } = useToken()
  const { t } = useTranslation()
  const {
    isAuthenticated,
    isServiceProvider,
    isServiceUser,
    error: authenticationError,
  } = useUser()
  const intendedPath = window.location.pathname

  if (loginInProgress) {
    return <CenteredSpinner message={t('please wait, authenticating')} />
  }

  if (authenticationError) {
    return <Login error={authenticationError} />
  }

  return (
    <RouterDomRoutes>
      <Route path="/login" element={<Login />} />
      <Route
        element={
          <HeaderRoute currentRoute={current} setCurrentRoute={setCurrent} />
        }
      >
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/data-privacy" element={<DataPrivacy />} />
        <Route path="/legal-information" element={<LegalInformation />} />
      </Route>

      {isServiceProvider && (
        <Route
          element={
            <ProtectedRoute
              current={current}
              setCurrent={setCurrent}
              intendedPath={intendedPath}
            />
          }
        >
          <Route path="/" element={<Orderedservices />} />
          <Route path="/user" element={<User />} />
          <Route path="/company" element={<Company />} />
          <Route path="/orderedservices" element={<Orderedservices />} />
          <Route path="/myprinters" element={<Myprinters />} />
          <Route path="/receivedrequests" element={<ReceivedRequests />} />
          <Route
            path="*"
            element={
              <NotFoundPage linkto="orderedservices" setCurrent={setCurrent} />
            }
          />
        </Route>
      )}

      {isServiceUser && (
        <Route
          element={
            <ProtectedRoute
              current={current}
              setCurrent={setCurrent}
              intendedPath={intendedPath}
            />
          }
        >
          <Route path="/" element={<Marketplace setCurrent={setCurrent} />} />
          <Route
            path="/marketplace"
            element={<Marketplace setCurrent={setCurrent} />}
          >
            <Route index element={<Marketplace setCurrent={setCurrent} />} />
            <Route path="orderservice" element={<Bookservice />} />
            {/* <Route path="account" element={<Account />} /> */}
          </Route>
          <Route path="/partsmasterdata" element={<Partsmasterdata />} />
          <Route path="/supply" element={<Supply />} />
          <Route path="/monitor" element={<Monitor />} />
          <Route path="/mymaterials" element={<Mymaterials />} />
          <Route path="/myservices" element={<Myservices />} />
          <Route path="/myorders" element={<Myorders />} />
          <Route path="/user" element={<User />} />
          <Route path="/company" element={<Company />} />
          <Route path="/general-terms" element={<GeneralTerms />} />
          <Route
            path="*"
            element={
              <NotFoundPage linkto="marketplace" setCurrent={setCurrent} />
            }
          />
        </Route>
      )}

      {!isAuthenticated && (
        <Route path="*" element={<Navigate to="login" replace />} />
      )}
    </RouterDomRoutes>
  )
}

export default Routes
