import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient, useQuery } from '@tanstack/react-query'
import { UilSearch } from '@iconscout/react-unicons'
import { Pagination, Divider, Flex } from 'antd'

import { fetchParts } from '$api/client'
import PartsListsTabs from '$components/PartsListsTabs'
import { StyledTable } from './styledComponents'
import PartNo from './PartNo'
import FilterDropdown from './FilterDropdown'
import { getDefaultColumnDefinitions, getColumnsArray } from './columns'
import DownloadPartsButton from '$components/FileDownload/DownloadPartsButton'
import { theme } from 'antd'

const { useToken } = theme

const DEFAULT_FILTER_COLUMNS = ['part_id', 'name', 'material']

const PartsTable = ({
  columns = ['part_id', 'name'],
  columnDefinitions = {},
  expandable = {},
  filterable = {},
  selectable = false,
  groupable = false,
  groupId,
  footer,
  onLoad = () => {},
  queryEnabled,
}) => {
  const { t } = useTranslation()
  const { token } = useToken()

  const [selectedParts, setSelectedParts] = useState({})

  // Preprocessing of `filterable` property
  if (filterable === true) {
    // allow short-hand notation `filterable={true}` to filter default columns
    //   and ignore all other options
    filterable = {
      columns: DEFAULT_FILTER_COLUMNS,
    }
  }
  // set default values if not set
  filterable = {
    columns: [],
    filters: {},
    onChange: () => {}, // void
    ...filterable,
  }

  // Preprocessing of `selectable` property
  if (selectable) {
    // allow short-hand notation `selectable={true}` to show row selection
    //   column and ignore all other options
    selectable = {
      show: true,
      ...selectable,
    }
  } else {
    selectable = {
      show: false,
    }
  }
  selectable = {
    selectedParts,
    setSelectedParts,
    ...selectable,
  }

  // Preprocessing of `groupable` property
  if (groupable === true) {
    // allow short-hand notation `groupable={true}` to show parts lists tabs
    //   and ignore all other options
    groupable = {
      show: true,
    }
  } else if (typeof groupable === 'object') {
    if (groupable.groupId && groupId) {
      console.warn(
        "Note that PartsTable's property 'groupId' is a short-hand notation for groupable.groupId. You provided both which may lead into unexpected behavior.",
      )
    }

    groupable = {
      show: true,
      ...groupable,
    }
  }
  // set default values if not set
  groupable = {
    show: false,
    groupId,
    setGroupId: () => {},
    setGroup: () => {},
    ...groupable,
  }

  const [loading, setLoading] = useState(true)
  const [currentParts, setCurrentParts] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [totalParts, setTotalParts] = useState()
  const [pageSize, setPageSize] = useState(10)
  const [pageNr, setPageNr] = useState(1)
  const [sorting, setSorting] = useState({})
  const [innerFilters, setInnerFilters] = useState(filterable.filters)
  const [innerGroupId, setInnerGroupId] = useState(groupable.groupId)

  const mergedFilters = useMemo(
    () => ({ ...filterable.filters, ...innerFilters }),
    [filterable.filters, innerFilters],
  )

  let classNames = new Set(['parts-table'])

  const queryFilters = {
    pageNr,
    pageSize,
    sorting,
    filters: mergedFilters,
    groupId: innerGroupId,
  }

  useQueryClient()
  useQuery({
    queryKey: ['parts', queryFilters],
    queryFn: fetchParts,
    enabled: queryEnabled,
    onSuccess: ({ items: parts, total }) => {
      setCurrentParts(parts)
      setTotalParts(total)
      setSelectedRowKeys(Object.keys(selectable.selectedParts))
      setLoading(false)
      onLoad(parts, total)
    },
  })

  const mergedColumnDefinitions = {
    ...getDefaultColumnDefinitions({ t, filters: innerFilters }),
    ...columnDefinitions,
  }

  const onChange = (_pagination, tableFilters, sorter, { action }) => {
    if (action === 'sort') {
      setSorting({
        key:
          sorter.field ||
          sorter.column?.field ||
          sorter.column?.title ||
          undefined,
        order: sorter.order === 'ascend' ? 1 : -1,
      })
      setPageNr(1)
    } else if (action === 'filter') {
      let newFilters = {}
      for (const [column, selectedKeys] of Object.entries(tableFilters)) {
        if (selectedKeys) {
          const [{ searchTerm, searchOperator }, ..._otherFilters] =
            selectedKeys
          if (typeof newFilters[column] !== 'object') {
            newFilters[column] = []
          }
          newFilters[column].push({
            parameter:
              mergedColumnDefinitions[column]?.filter?.parameter ?? column,
            logical_operator: searchOperator ?? 'contains',
            value: searchTerm,
          })
        }
      }
      setInnerFilters(newFilters)
      filterable.onChange(newFilters)
      setPageNr(1)
    }
  }

  let rowSelection
  if (selectable.show === true) {
    // If `selectable.show` is true,
    //   (i) we'll add a column to select parts,
    //   (ii) we'll show a footer that states the number of selected parts

    const onSelectMultiple = (selected, _selectedRows, changeRows) => {
      if (selected) {
        changeRows.forEach((part) => {
          selectable.selectedParts[part.id] = part
        })
      } else {
        changeRows.forEach((part) => {
          delete selectable.selectedParts[part.id]
        })
      }
      selectable.setSelectedParts(selectable.selectedParts)
    }

    rowSelection = {
      selectedRowKeys,
      onSelect: (part, selected) => {
        if (selected) {
          selectable.selectedParts[part.id] = part
        } else {
          delete selectable.selectedParts[part.id]
        }
        selectable.setSelectedParts(selectable.selectedParts)
      },
      onSelectMultiple: onSelectMultiple,
      onSelectAll: onSelectMultiple,
      onChange: (_selectedRowKeys, selectedRows) => {
        selectedRows.forEach((part) => {
          selectable.selectedParts[part.id] = part
        })
        selectable.setSelectedParts({ ...selectable.selectedParts }) // Update the state with a new object, ensuring rerendering of selectedParts
        setSelectedRowKeys(Object.keys(selectable.selectedParts))
      },
    }

    // If none is provided via props, add a footer that shows the
    //   number of selected parts.
    if (typeof footer !== 'function') {
      footer = () => {
        const size = Object.keys(selectable.selectedParts).length
        if (size === 0) {
          return ''
        }
        return <i>{t('parts_selected', { count: size })}</i>
      }
    }
  }

  let expandableDefinition = {}
  if (typeof expandable.expandedRowRender === 'function') {
    // If `expandable` is passed to the component with a `expandedRowRender`, it
    //   is used for row expansion.
    // By default, this adds the ExpandColumn at the very beginning of the table.
    classNames.add('expandable')

    const onExpand = (expanded, record) => {
      if (expanded) {
        setExpandedRowKeys([...expandedRowKeys, record.id])
      } else {
        setExpandedRowKeys(
          expandedRowKeys.filter((rowKey) => rowKey !== record.id),
        )
      }
    }

    expandableDefinition = {
      expandedRowKeys,
      onExpand,
      showExpandColumn: undefined,
      expandRowByClick: false,
      ...expandable,
    }

    // If `selectedParts` is passed to the component as well and thus a rowSelection
    //   is set-up, we avoid having both the ExpandColumn and SelectColumn at the
    //   table's beginning and use either the column 'Part No' or 'Part Name' as
    //   expander column by default.
    if (
      rowSelection &&
      columns.includes('part_id') &&
      typeof columnDefinitions?.part_id?.render === 'undefined' &&
      expandable.showExpandColumn !== true
    ) {
      if (typeof expandableDefinition.showExpandColumn === 'undefined') {
        // override only if not previously set via the `expandable` parameter
        expandableDefinition.showExpandColumn = false
      }

      // use different, interactive renderer for `part_id` column
      mergedColumnDefinitions.part_id.render = (_id, part) => (
        <PartNo
          part={part}
          expandedRowKeys={expandedRowKeys}
          onExpand={onExpand}
        />
      )
    }
  }

  for (const column of filterable.columns) {
    mergedColumnDefinitions[column].filterDropdown = ({
      confirm,
      clearFilters,
      setSelectedKeys,
    }) => (
      <FilterDropdown
        confirm={confirm}
        clearFilters={clearFilters}
        setSelectedKeys={setSelectedKeys}
        operators={
          mergedColumnDefinitions[column].filter?.operators ?? [
            mergedColumnDefinitions[column].filter?.operator ?? '=',
          ]
        }
        parseValue={
          mergedColumnDefinitions[column].filter?.parse ?? ((id) => id)
        }
      />
    )
    mergedColumnDefinitions[column].filterIcon = (filtered) => (
      <UilSearch
        style={{
          color: filtered ? token.colorPrimary : undefined,
          width: '15px',
        }}
      />
    )
  }

  return (
    <>
      {groupable.show && (
        <PartsListsTabs
          groupId={innerGroupId}
          setGroupId={(groupId) => {
            setInnerGroupId(groupId)
            groupable.setGroupId(groupId)
            setPageNr(1)
          }}
          setGroup={groupable.setGroup}
        />
      )}
      <StyledTable
        className={[...classNames].join(' ')}
        loading={loading}
        size="middle"
        expandable={expandableDefinition}
        columns={getColumnsArray(t, columns, mergedColumnDefinitions)}
        rowKey={(part) => part.id}
        dataSource={currentParts}
        pagination={false}
        onChange={onChange}
        rowSelection={rowSelection}
        footer={() => (
          <Flex justify="space-between" align="center">
            {/* render custom footer */}
            <div>{footer ? footer(selectable.selectedParts) : undefined}</div>
            {/* render fixed footer content */}
            <div style={{ display: 'inline-flex' }}>
              <DownloadPartsButton size="small" queryFilters={queryFilters} />
              <Divider type="vertical" style={{ height: 'unset' }} />
              <Pagination
                size="small"
                current={pageNr}
                pageSize={pageSize}
                total={totalParts}
                onChange={(page, pageSize) => {
                  setLoading(true)
                  setPageNr(page)
                  setPageSize(pageSize)
                }}
              />
            </div>
          </Flex>
        )}
      />
    </>
  )
}

export default PartsTable
