import ServiceStatusButtons from '../../../PartsTable/ServiceStatusButtons'
import { PartNoBg } from '../styledComponents'
import TruncateText from '$components/TruncateText/TruncateText'

const createColumnsNotFeasible = (t) => {
  const columns = [
    // {
    //   title: "Feasible",
    //   dataIndex: "feasible",
    //   className: "feas-col"
    // },
    //Table.EXPAND_COLUMN,
    {
      title: t('Part No'),
      dataIndex: 'id_part_client', //data["id_part_client"],
      //...getColumnSearchProps('id_part_client'),
      render: (title) => <PartNoBg>{title}</PartNoBg>,
      width: '160px',
    },
    {
      title: t('Part Name'),
      dataIndex: 'name',
      render: (text) => <TruncateText text={text} />,
      ellipsis: true,
      //...getColumnSearchProps('name'),
    },
    {
      title: '',
      //dataIndex: "dimensions",
      //dataIndex: "attributes", //['attributes','0','value'],
      //render: () => <Button  style={{margin: "-6px 0"}} type="secondary" shape="circle" icon={<UilCheck style={{height:"19px", color:"#7CC334", margin: "2px 0px"}} />}/>
    },
    {
      title: t('Services'),
      width: '265px',
      render: (record) => (
        <ServiceStatusButtons variant={record.status_attributes} />
      ),
    },
  ]

  return columns
}

export default createColumnsNotFeasible
